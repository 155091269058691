import React from 'react';
import Scrollspy from 'react-scrollspy';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withRoot from '../../../../withRoot';
import './index.css';

const styles = theme => ({});

class VisionDetails extends React.Component {
  render() {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            overflow: 'hidden',
            height: 100,
            position: 'fixed',
            width: '100%'
          }}
        >
          <Scrollspy
            items={['section-1', 'section-2', 'section-3']}
            currentClassName="is-current"
          >
            <li>
              <a href="#section-1">section 1</a>
            </li>
            <li>
              <a href="#section-2">section 2</a>
            </li>
            <li>
              <a href="#section-3">section 3</a>
            </li>
          </Scrollspy>
        </div>
        <div>
          <section id="section-1">section 1</section>
          <section id="section-2">section 2</section>
          <section id="section-3">section 3</section>
        </div>
      </div>
    );
  }
}

VisionDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles, { withTheme: true })(VisionDetails));
