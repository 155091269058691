import React from 'react';
import withRoot from '../withRoot';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import arrayMove from 'array-move';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import DragHandleIcon from '@material-ui/icons/DragHandle';

const styles = (theme => ({
  root: {
    width: '100%',
    display: 'flex',
    marginTop: 15,
    flexDirection: window.screen.availWidth < 780 ? 'column' : 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-around'
  },
  topPanels: {
    width: '100%',
    display: 'flex',
    flexDirection: window.screen.availWidth < 780 ? 'column' : 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-around'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: theme.spacing(20)
  },
  heading: {
    fontSize: theme.typography.pxToRem(19),
    flexBasis: '60%',
    flexShrink: 0,
    fontWeight: 'bold',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary
  },
  button: {
    margin: theme.spacing(2),
    width: '50%',
    alignSelf: 'center',
    backgroundColor: '#2962ff'
  },
  buttonMid: {
    alignSelf: 'flex-end',
    backgroundColor: '#2962ff'
  },
  fontt: {
    fontSize: '14em'
  },
  card: {
    width: '98%',
    alignSelf: 'center',
    marginBottom: 15
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150,
  },
}));


const SortableItem = sortableElement(({ indx, value }) => {
  return (
    <div style={{
      margin: 10, display: 'flex', flexDirection: 'row', padding: '10px 10px 10px 10px',
      backgroundColor: '#82b1ff', borderRadius: 10, alignItems: 'center', width: '95%'
    }}>
      <DragHandleIcon style={{ color: 'white', marginRight: '15px' }} />
      <Typography style={{ color: '#363636', textAlign: 'center', fontSize: '1.5em' }}>{indx + 1}</Typography>
      <Typography style={{ color: '#363636', textAlign: 'center', marginLeft: 20, fontSize: '1.3em' }}>{value}</Typography>
    </div>
  )
});
const SortableItem2 = sortableElement(({ indx, value, start }) => {
  return (
    <div style={{
      margin: 5, display: 'flex', flexDirection: 'row', padding: '2px 10px 2px 10px',
      backgroundColor: '#82b1ff', borderRadius: 10, alignItems: 'center', width: '30%'
    }}>
      <DragHandleIcon style={{ color: 'white', marginRight: '15px' }} />
      <Typography style={{ color: '#363636', textAlign: 'center', fontSize: '1.4em' }}>{indx + start}</Typography>
      <Typography style={{ color: '#363636', textAlign: 'center', fontSize: '1.2em', marginLeft: 15 }}>{value}</Typography>
    </div>
  )
});
const SortableContainer = sortableContainer(({ children }) => {
  return <div style={{ display: 'flex', flexWrap: 'wrap' }}>{children}</div>;
});

class Dashboard extends React.Component {
  constructor() {
    super();

    this.state = {
      panel1: true,
      panel2: true,
      panel3: true,

      rateItems: ['Promotional Rate', 'Contract Rate', 'Preferrential Tariff', 'Service Tariff', 'Standard Tariff'],

      attributeItems: ["Shipper", "Consignee", "Flight Number", "Flight Group", "Via Airport", "Commodity", "Commodity Group",
        "Service", "Shc", "Shc Group", "Origin Airport", "Dest Airport", "Origin City", "Dest City", "Origin Airport Zone",
        "Dest Airport Zone", "Origin City Zone", "Dest City Zone", "Origin Country", "Dest Country", "Origin Country Zone",
        "Dest Country Zone", "Origin Sales Area", "Dest Sales Area", "Origin Ext Sales Area", "Dest Ext Sales Area",
        "Origin Sub Region", "Dest Sub Region", "Origin Sales Region", "Dest Sales Region", "Origin TC Area",
        "Destination TC Area", "Customer", "Customer Group", "Value Added Prod", "UN Number", "UN Group", "Itinerary",
        "Via City", "Carrier", "Dist Channels", "Other Carrier", "Uplifting Airline Pref",],
    }
  }

  handleChange(panel) {
    if (panel === 'panel1') {
      this.setState({ panel1: !this.state.panel1 });
    } else if (panel === 'panel2') {
      this.setState({ panel2: !this.state.panel2 });
    } else {
      this.setState({ panel3: !this.state.panel3 });
    }
  }

  onSortRateHierarchy = ({ oldIndex, newIndex }) => {
    this.setState(({ rateItems }) => ({
      rateItems: arrayMove(rateItems, oldIndex, newIndex),
    }));
  };

  onSortAttribHierarchy = ({ oldIndex, newIndex }) => {
    this.setState(({ attributeItems }) => ({
      attributeItems: arrayMove(attributeItems, oldIndex, newIndex),
    }));
  };


  render() {
    const { classes } = this.props;
    const { panel1, panel2, panel3, rateItems, attributeItems } = this.state;

    return (
      <div className={classes.root} >

        <Card className={classes.card}>
          <CardContent style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <TextField
                id="date"
                label="Effective Date"
                type="date"
                defaultValue=""
                className={classes.dateField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date"
                label="Expiry Date"
                type="date"
                defaultValue=""
                className={classes.dateField}
                style={{ marginLeft: 20 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonMid}
                style={{ marginRight: 20 }}
                startIcon={<SaveIcon />}
              >
                Save As Draft
            </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonMid}
                startIcon={<PublishIcon />}
              >
                Submit For Approval
            </Button>
            </div>
          </CardContent>
        </Card>

        <div className={classes.topPanels} >
          <ExpansionPanel style={{ width: window.screen.availWidth < 780 ? '100%' : '25%' }} expanded={panel1} onChange={() => this.handleChange('panel1')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>Rate Type Hierarchy</Typography>
              <Typography className={classes.secondaryHeading}>Sort rate types based on priority</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ display: 'flex', width: '100%', marginTop: -15, justifyContent: 'center' }}>
                <SortableContainer onSortEnd={this.onSortRateHierarchy}>
                  {rateItems.map((value, index) => (
                    <SortableItem key={`item-${value}`} index={index} indx={index} value={value} />
                  ))}
                </SortableContainer>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel style={{ width: window.screen.availWidth < 780 ? '100%' : '72%', marginTop: 0 }}
            expanded={panel2} onChange={() => this.handleChange('panel2')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>Attribute Priorities</Typography>
              <Typography className={classes.secondaryHeading}>
                Sort attribute based on priority
          </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{
                display: 'flex', flexDirection: window.screen.availWidth < 780 ? 'column' : 'row',
                width: '100%', marginTop: -15, justifyContent: 'center'
              }}>
                <SortableContainer onSortEnd={this.onSortAttribHierarchy} axis={'xy'}>
                  {attributeItems.map((value, index) => (
                    <SortableItem2 key={`item-${value}`} index={index} indx={index} start={1} value={value} />
                  ))}
                </SortableContainer>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>

        <ExpansionPanel style={{
          width: window.screen.availWidth < 780 ? '100%' : '98%', alignSelf: 'center',
          marginTop: 15, marginBottom: 10
        }} expanded={panel3} onChange={() => this.handleChange('panel3')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.heading}>Business Rules</Typography>
            <Typography className={classes.secondaryHeading}>
              Set custom business rules here
          </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ flexDirection: 'column', display: 'flex', flex: 1 }}>
              <TextareaAutosize
                rows={18}
                rowsMax={30}
                style={{ width: '100%' }}
                aria-label="maximum height"
                placeholder="Enter business rules custom code here"
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
              // startIcon={<SaveIcon />}
              >
                Validate
            </Button>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div >
    )
  }
}

export default withRouter(
  withRoot(withStyles(styles, { withTheme: true })(Dashboard))
);