import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import withRoot from '../../withRoot';

const styles = theme => ({
  topMargin: {
    marginTop: '5vh'
  },
  titleText: {
    color: '#202020',
    fontWeight: 'bold',
    fontSize: '5vmin',
    textAlign: 'center'
  },
  bigText: {
    color: '#606060',
    fontSize: '3vmin',
    textAlign: 'center'
  },
  subtitleText: {
    color: '#606060',
    fontSize: '2vmin',
    textAlign: 'center'
  },
  paper: {
    width: '60%',
    marginTop: '7vh',
    alignSelf: 'center',
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
});

function TabsView(props) {
  const { classes, value, handleChange, data } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography className={`${classes.titleText} ${classes.topMargin}`}>
        {data.title}
      </Typography>
      <Typography className={`${classes.bigText} ${classes.topMargin}`}>
        {data.subtitle}
      </Typography>

      <Paper className={classes.paper}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          style={{ borderBottom: '1px solid #e8e8e8' }}
        >
          {data.tabs && data.tabs.map(itm => <Tab label={itm.name} />)}
        </Tabs>

        <Typography className={classes.bigText}>
          {data.tabs[value].content &&
            data.tabs[value].content.map(itm => {
              let img = itm.image;
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '2vh'
                  }}
                >
                  <div style={{ flex: 0.5, alignSelf: 'center' }}>
                    <img
                      src={itm.image}
                      alt={itm.title}
                      style={{ width: '85%', borderRadius: 15 }}
                    />
                  </div>
                  <div style={{ flex: 0.5 }}>
                    <Typography
                      className={classes.titleText}
                      style={{
                        textAlign: 'left',
                        fontSize: '3vmin',
                        marginTop: '1vh'
                      }}
                    >
                      {itm.title}
                    </Typography>
                    <Typography
                      className={classes.bigText}
                      style={{
                        textAlign: 'left',
                        fontSize: '2.5vmin',
                        marginTop: '1vh'
                      }}
                    >
                      {itm.subtitle}
                    </Typography>
                    <Typography
                      className={classes.subtitleText}
                      style={{
                        textAlign: 'left',
                        marginTop: '1vh'
                      }}
                    >
                      {itm.description}
                    </Typography>
                    <Typography
                      className={classes.subtitleText}
                      style={{
                        textAlign: 'left',
                        marginTop: '1vh'
                      }}
                    >
                      {'More Here : '}
                      <a
                        style={{
                          color: 'blue',
                          textDecoration: 'underline',
                          textDecorationColor: 'blue'
                        }}
                        href={itm.link}
                      >
                        {itm.link}
                      </a>
                    </Typography>
                  </div>
                </div>
              );
            })}
        </Typography>
      </Paper>
    </div>
  );
}

TabsView.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles, { withTheme: true })(TabsView));
