import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withRoot from '../../../withRoot';

import TabsView from '../../../components/ContentViews/TabsView';
import DividerView from '../../../components/ContentViews/Divider';
import DescriptionView from '../../../components/ContentViews/DescriptionView';

import contentJson from '../../../assets/jsons/AiMachineHome.json';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  appBar: {
    marginLeft: 10,
    color: '#606060',
    fontSize: '3vmin'
  },
  button: {
    margin: theme.spacing.unit,
    color: '#4c4c4c',
    fontSize: '2vmin'
  },
  titleText: {
    marginTop: '5vmin',
    color: '#202020',
    fontWeight: 'bold',
    fontSize: '5vmin',
    textAlign: 'center'
  },
  subtitleText: {
    paddingLeft: '5vw',
    paddingRight: '5vw',
    marginTop: '5vmin',
    color: '#606060',
    fontSize: '2vmin',
    textAlign: 'center'
  }
});

class AiMachineProducts extends React.Component {
  state = {
    aibuild_Tab_Value: 0,
    aiplatform_Tab_Value: 0
  };

  aibuild_Handle_Tabs(evt, value) {
    this.setState({ aibuild_Tab_Value: value });
  }
  aiplatform_Handle_Tabs(evt, value) {
    this.setState({ aiplatform_Tab_Value: value });
  }

  renderTopAppBar = props => {
    const { classes } = props;

    return (
      <AppBar position="sticky" color="default">
        <Toolbar>
          <Typography variant="h5" color="inherit" className={classes.appBar}>
            {'AI & Machine Learning Products'}
          </Typography>
        </Toolbar>
      </AppBar>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderTopAppBar(this.props)}

        <DescriptionView data={contentJson.content.description} />
        <DividerView />
        <TabsView
          value={this.state.aibuild_Tab_Value}
          handleChange={this.aibuild_Handle_Tabs.bind(this)}
          data={contentJson.content.ai_building_blocks}
        />
        <DividerView />
        <TabsView
          value={this.state.aiplatform_Tab_Value}
          handleChange={this.aiplatform_Handle_Tabs.bind(this)}
          data={contentJson.content.ai_platform}
        />
        <DividerView />
      </div>
    );
  }
}

AiMachineProducts.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(
  withStyles(styles, { withTheme: true })(AiMachineProducts)
);
