import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';

const styles = theme => ({});

function Divider(props) {
  return (
    <div
      style={{
        marginTop: '10vh',
        marginBottom: '2vh',
        width: '90%',
        backgroundColor: 'grey',
        height: 0.5,
        alignSelf: 'center'
      }}
    />
  );
}

export default withRoot(withStyles(styles, { withTheme: true })(Divider));
