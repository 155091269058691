import React, { Component } from 'react';
import Routes from '../routes';

class App extends Component {
  render() {
    return (
      <div>
        {/* <TopAppBar> */}
          <Routes />
        {/* </TopAppBar> */}
      </div>
    );
  }
}

export default App;
