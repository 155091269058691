import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Button from '@material-ui/core/Button';
import LabelIcon from '@material-ui/icons/Label';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import withRoot from '../withRoot';
import { withRouter } from 'react-router-dom';

import { ReactComponent as AiLogoSvg } from '../assets/ai_logo.svg';
import menuJson from '../assets/jsons/AppbarMenu.json';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    fontWeight: 'bold',
    marginLeft: 10,
    color: '#404040',
    fontSize: '2vmax'
  },
  button: {
    margin: theme.spacing.unit,
    color: '#4c4c4c',
    fontSize: '2vmin'
  },
  buttonText: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center'
  }
});

class TopAppBar extends React.Component {
  state = {
    appBarMenuOpen: false
  };

  handleAppBarMenu = (anchor, menuJson) => {
    this.currentButtonAnchor = anchor;
    this.currentMenuItems = menuJson;
    this.setState({ appBarMenuOpen: !this.state.appBarMenuOpen });
  };

  handleMenuClick = ind => {
    this.setState({ appBarMenuOpen: !this.state.appBarMenuOpen });

    this.props.history.replace(this.currentMenuItems[ind].link);
  };

  renderTopAppBar = props => {
    const { classes } = props;

    return (
      <AppBar position="sticky" color="default">
        <Toolbar>
          <AiLogoSvg
            style={{ width: '3vmax', height: '3vmax' }}
            onClick={() => {
              this.props.history.replace('/');
            }}
          />
          <Typography
            variant="h5"
            color="inherit"
            className={classes.appBar}
            onClick={() => {
              this.props.history.replace('/');
            }}
          >
            AFLS Pricing Rules Definition
          </Typography>
          {this.renderAppBarButtons(props)}
        </Toolbar>
      </AppBar>
    );
  };

  renderAppBarButtons = props => {
    const { classes } = props;

    return (
      <div style={{ marginLeft: 30 }}>
        {/* <Button
          buttonRef={node => {
            this.menuButtonAnchor1 = node;
          }}
          className={classes.button}
          onClick={() =>
            this.handleAppBarMenu(this.menuButtonAnchor1, menuJson.afls)
          }
        >
          Why Afls
        </Button> */}
        {/* <Button
          buttonRef={node => {
            this.menuButtonAnchor2 = node;
          }}
          className={classes.button}
          onClick={() =>
            this.handleAppBarMenu(this.menuButtonAnchor2, menuJson.solutions)
          }
        >
          Solutions
        </Button> */}
        {/* <Button
          buttonRef={node => {
            this.menuButtonAnchor3 = node;
          }}
          className={classes.button}
          onClick={() =>
            this.handleAppBarMenu(this.menuButtonAnchor3, menuJson.products)
          }
        >
          <div className={classes.buttonText}>
            Products
            <DropDownIcon
              style={{
                width: '3vh',
                height: '3vh',
                marginTop: '-1vh',
                marginBottom: '-1vh'
              }}
            />
          </div>
        </Button> */}
      </div>
    );
  };

  renderAppBarMenu = () => {
    return (
      <Popper
        open={this.state.appBarMenuOpen}
        anchorEl={this.currentButtonAnchor}
        style={{ zIndex: 1101 }}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  this.setState({ appBarMenuOpen: !this.state.appBarMenuOpen });
                }}
              >
                <MenuList>
                  {this.currentMenuItems.map((item, ind) => {
                    return (
                      <MenuItem onClick={() => this.handleMenuClick(ind)}>
                        <LabelIcon
                          color="secondary"
                          style={{
                            marginRight: 10
                          }}
                        />
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderTopAppBar(this.props)}
        {this.renderAppBarMenu()}

        {this.props.children}
      </React.Fragment>
    );
  }
}

TopAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(
  withRoot(withStyles(styles, { withTheme: true })(TopAppBar))
);
