import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import withRoot from '../../withRoot';

const styles = theme => ({
  titleText: {
    marginTop: '5vmin',
    color: '#202020',
    fontWeight: 'bold',
    fontSize: '5vmin',
    textAlign: 'center'
  },
  bigText: {
    marginTop: '5vmin',
    color: '#606060',
    fontSize: '3vmin',
    textAlign: 'center'
  },
  subtitleText: {
    paddingLeft: '5vw',
    paddingRight: '5vw',
    marginTop: '5vmin',
    color: '#606060',
    fontSize: '2vmin',
    textAlign: 'center'
  },
  descContainer: {
    width: '50%',
    marginTop: '15vh',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center'
  },
  descText: {
    color: '#606060',
    fontSize: '2vmin',
    textAlign: 'left'
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: '2.5vmin'
  }
});

function DescriptionView(props) {
  const { classes, data } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography className={classes.titleText}>{data.title}</Typography>
      <Typography className={classes.bigText}>{data.subtitle}</Typography>
      <div className={classes.descContainer}>
        {data.content &&
          data.content.map(itm => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 0.5
              }}
            >
              <CheckIcon
                color="secondary"
                style={{ width: '3vw', height: '2vw' }}
              />
              <Typography className={(classes.descText, classes.boldText)}>
                {itm.name}
                <Typography className={classes.descText}>
                  {itm.description}
                </Typography>
              </Typography>
            </div>
          ))}
      </div>
    </div>
  );
}

DescriptionView.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default withRoot(
  withStyles(styles, { withTheme: true })(DescriptionView)
);
