import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import AiMachineProducts from './pages/products/Ai-machine';
import AiVisionDetails from './pages/products/Ai-machine/Vision';
import MobileProducts from './pages/products/Mobile';
import DatalakeProducts from './pages/products/Datalake';
import TopAppBar from './components/TopAppBar';
// import ScrollToTop from './components/ScrollTop';

export default props => (
  <BrowserRouter>
    {/* <ScrollToTop> */}
    <Switch>
      <TopAppBar>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/ai" component={AiMachineProducts} />
        <Route exact path="/ai2" component={AiVisionDetails} />
        <Route exact path="/mobile" component={MobileProducts} />
        <Route exact path="/datalake" component={DatalakeProducts} />
      </TopAppBar>
    </Switch>
    {/* </ScrollToTop> */}
  </BrowserRouter>
);
